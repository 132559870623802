import _jquery from "jquery";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
!function (a) {
  a(_jquery);
}(function (a, b) {
  function c() {
    return new Date(Date.UTC.apply(Date, arguments));
  }

  function d() {
    var a = new Date();
    return c(a.getFullYear(), a.getMonth(), a.getDate());
  }

  function e(a, b) {
    return a.getUTCFullYear() === b.getUTCFullYear() && a.getUTCMonth() === b.getUTCMonth() && a.getUTCDate() === b.getUTCDate();
  }

  function f(c, d) {
    return function () {
      return d !== b && a.fn.datepicker.deprecated(d), (this || _global)[c].apply(this || _global, arguments);
    };
  }

  function g(a) {
    return a && !isNaN(a.getTime());
  }

  function h(b, c) {
    function d(a, b) {
      return b.toLowerCase();
    }

    var e,
        f = a(b).data(),
        g = {},
        h = new RegExp("^" + c.toLowerCase() + "([A-Z])");
    c = new RegExp("^" + c.toLowerCase());

    for (var i in f) c.test(i) && (e = i.replace(h, d), g[e] = f[i]);

    return g;
  }

  function i(b) {
    var c = {};

    if (q[b] || (b = b.split("-")[0], q[b])) {
      var d = q[b];
      return a.each(p, function (a, b) {
        b in d && (c[b] = d[b]);
      }), c;
    }
  }

  var j = function () {
    var b = {
      get: function (a) {
        return this.slice(a)[0];
      },
      contains: function (a) {
        for (var b = a && a.valueOf(), c = 0, d = (this || _global).length; c < d; c++) if (0 <= (this || _global)[c].valueOf() - b && (this || _global)[c].valueOf() - b < 86400000) return c;

        return -1;
      },
      remove: function (a) {
        this.splice(a, 1);
      },
      replace: function (b) {
        b && (a.isArray(b) || (b = [b]), this.clear(), (this || _global).push.apply(this || _global, b));
      },
      clear: function () {
        (this || _global).length = 0;
      },
      copy: function () {
        var a = new j();
        return a.replace(this || _global), a;
      }
    };
    return function () {
      var c = [];
      return c.push.apply(c, arguments), a.extend(c, b), c;
    };
  }(),
      k = function (b, c) {
    a.data(b, "datepicker", this || _global), (this || _global)._events = [], (this || _global)._secondaryEvents = [], this._process_options(c), (this || _global).dates = new j(), (this || _global).viewDate = (this || _global).o.defaultViewDate, (this || _global).focusDate = null, (this || _global).element = a(b), (this || _global).isInput = (this || _global).element.is("input"), (this || _global).inputField = (this || _global).isInput ? (this || _global).element : (this || _global).element.find("input"), (this || _global).component = !!(this || _global).element.hasClass("date") && (this || _global).element.find(".add-on, .input-group-addon, .input-group-append, .input-group-prepend, .btn"), (this || _global).component && 0 === (this || _global).component.length && ((this || _global).component = !1), (this || _global).isInline = !(this || _global).component && (this || _global).element.is("div"), (this || _global).picker = a(r.template), this._check_template((this || _global).o.templates.leftArrow) && (this || _global).picker.find(".prev").html((this || _global).o.templates.leftArrow), this._check_template((this || _global).o.templates.rightArrow) && (this || _global).picker.find(".next").html((this || _global).o.templates.rightArrow), this._buildEvents(), this._attachEvents(), (this || _global).isInline ? (this || _global).picker.addClass("datepicker-inline").appendTo((this || _global).element) : (this || _global).picker.addClass("datepicker-dropdown dropdown-menu"), (this || _global).o.rtl && (this || _global).picker.addClass("datepicker-rtl"), (this || _global).o.calendarWeeks && (this || _global).picker.find(".datepicker-days .datepicker-switch, thead .datepicker-title, tfoot .today, tfoot .clear").attr("colspan", function (a, b) {
      return Number(b) + 1;
    }), this._process_options({
      startDate: (this || _global)._o.startDate,
      endDate: (this || _global)._o.endDate,
      daysOfWeekDisabled: (this || _global).o.daysOfWeekDisabled,
      daysOfWeekHighlighted: (this || _global).o.daysOfWeekHighlighted,
      datesDisabled: (this || _global).o.datesDisabled
    }), (this || _global)._allow_update = !1, this.setViewMode((this || _global).o.startView), (this || _global)._allow_update = !0, this.fillDow(), this.fillMonths(), this.update(), (this || _global).isInline && this.show();
  };

  k.prototype = {
    constructor: k,
    _resolveViewName: function (b) {
      return a.each(r.viewModes, function (c, d) {
        if (b === c || -1 !== a.inArray(b, d.names)) return b = c, !1;
      }), b;
    },
    _resolveDaysOfWeek: function (b) {
      return a.isArray(b) || (b = b.split(/[,\s]*/)), a.map(b, Number);
    },
    _check_template: function (c) {
      try {
        if (c === b || "" === c) return !1;
        if ((c.match(/[<>]/g) || []).length <= 0) return !0;
        return a(c).length > 0;
      } catch (a) {
        return !1;
      }
    },
    _process_options: function (b) {
      (this || _global)._o = a.extend({}, (this || _global)._o, b);
      var e = (this || _global).o = a.extend({}, (this || _global)._o),
          f = e.language;
      q[f] || (f = f.split("-")[0], q[f] || (f = o.language)), e.language = f, e.startView = this._resolveViewName(e.startView), e.minViewMode = this._resolveViewName(e.minViewMode), e.maxViewMode = this._resolveViewName(e.maxViewMode), e.startView = Math.max((this || _global).o.minViewMode, Math.min((this || _global).o.maxViewMode, e.startView)), !0 !== e.multidate && (e.multidate = Number(e.multidate) || !1, !1 !== e.multidate && (e.multidate = Math.max(0, e.multidate))), e.multidateSeparator = String(e.multidateSeparator), e.weekStart %= 7, e.weekEnd = (e.weekStart + 6) % 7;
      var g = r.parseFormat(e.format);
      e.startDate !== -1 / 0 && (e.startDate ? e.startDate instanceof Date ? e.startDate = this._local_to_utc(this._zero_time(e.startDate)) : e.startDate = r.parseDate(e.startDate, g, e.language, e.assumeNearbyYear) : e.startDate = -1 / 0), e.endDate !== 1 / 0 && (e.endDate ? e.endDate instanceof Date ? e.endDate = this._local_to_utc(this._zero_time(e.endDate)) : e.endDate = r.parseDate(e.endDate, g, e.language, e.assumeNearbyYear) : e.endDate = 1 / 0), e.daysOfWeekDisabled = this._resolveDaysOfWeek(e.daysOfWeekDisabled || []), e.daysOfWeekHighlighted = this._resolveDaysOfWeek(e.daysOfWeekHighlighted || []), e.datesDisabled = e.datesDisabled || [], a.isArray(e.datesDisabled) || (e.datesDisabled = e.datesDisabled.split(",")), e.datesDisabled = a.map(e.datesDisabled, function (a) {
        return r.parseDate(a, g, e.language, e.assumeNearbyYear);
      });
      var h = String(e.orientation).toLowerCase().split(/\s+/g),
          i = e.orientation.toLowerCase();
      if (h = a.grep(h, function (a) {
        return /^auto|left|right|top|bottom$/.test(a);
      }), e.orientation = {
        x: "auto",
        y: "auto"
      }, i && "auto" !== i) {
        if (1 === h.length) switch (h[0]) {
          case "top":
          case "bottom":
            e.orientation.y = h[0];
            break;

          case "left":
          case "right":
            e.orientation.x = h[0];
        } else i = a.grep(h, function (a) {
          return /^left|right$/.test(a);
        }), e.orientation.x = i[0] || "auto", i = a.grep(h, function (a) {
          return /^top|bottom$/.test(a);
        }), e.orientation.y = i[0] || "auto";
      } else ;
      if (e.defaultViewDate instanceof Date || "string" == typeof e.defaultViewDate) e.defaultViewDate = r.parseDate(e.defaultViewDate, g, e.language, e.assumeNearbyYear);else if (e.defaultViewDate) {
        var j = e.defaultViewDate.year || new Date().getFullYear(),
            k = e.defaultViewDate.month || 0,
            l = e.defaultViewDate.day || 1;
        e.defaultViewDate = c(j, k, l);
      } else e.defaultViewDate = d();
    },
    _applyEvents: function (a) {
      for (var c, d, e, f = 0; f < a.length; f++) c = a[f][0], 2 === a[f].length ? (d = b, e = a[f][1]) : 3 === a[f].length && (d = a[f][1], e = a[f][2]), c.on(e, d);
    },
    _unapplyEvents: function (a) {
      for (var c, d, e, f = 0; f < a.length; f++) c = a[f][0], 2 === a[f].length ? (e = b, d = a[f][1]) : 3 === a[f].length && (e = a[f][1], d = a[f][2]), c.off(d, e);
    },
    _buildEvents: function () {
      var b = {
        keyup: a.proxy(function (b) {
          -1 === a.inArray(b.keyCode, [27, 37, 39, 38, 40, 32, 13, 9]) && this.update();
        }, this || _global),
        keydown: a.proxy((this || _global).keydown, this || _global),
        paste: a.proxy((this || _global).paste, this || _global)
      };
      !0 === (this || _global).o.showOnFocus && (b.focus = a.proxy((this || _global).show, this || _global)), (this || _global).isInput ? (this || _global)._events = [[(this || _global).element, b]] : (this || _global).component && (this || _global).inputField.length ? (this || _global)._events = [[(this || _global).inputField, b], [(this || _global).component, {
        click: a.proxy((this || _global).show, this || _global)
      }]] : (this || _global)._events = [[(this || _global).element, {
        click: a.proxy((this || _global).show, this || _global),
        keydown: a.proxy((this || _global).keydown, this || _global)
      }]], (this || _global)._events.push([(this || _global).element, "*", {
        blur: a.proxy(function (a) {
          (this || _global)._focused_from = a.target;
        }, this || _global)
      }], [(this || _global).element, {
        blur: a.proxy(function (a) {
          (this || _global)._focused_from = a.target;
        }, this || _global)
      }]), (this || _global).o.immediateUpdates && (this || _global)._events.push([(this || _global).element, {
        "changeYear changeMonth": a.proxy(function (a) {
          this.update(a.date);
        }, this || _global)
      }]), (this || _global)._secondaryEvents = [[(this || _global).picker, {
        click: a.proxy((this || _global).click, this || _global)
      }], [(this || _global).picker, ".prev, .next", {
        click: a.proxy((this || _global).navArrowsClick, this || _global)
      }], [(this || _global).picker, ".day:not(.disabled)", {
        click: a.proxy((this || _global).dayCellClick, this || _global)
      }], [a(window), {
        resize: a.proxy((this || _global).place, this || _global)
      }], [a(document), {
        "mousedown touchstart": a.proxy(function (a) {
          (this || _global).element.is(a.target) || (this || _global).element.find(a.target).length || (this || _global).picker.is(a.target) || (this || _global).picker.find(a.target).length || (this || _global).isInline || this.hide();
        }, this || _global)
      }]];
    },
    _attachEvents: function () {
      this._detachEvents(), this._applyEvents((this || _global)._events);
    },
    _detachEvents: function () {
      this._unapplyEvents((this || _global)._events);
    },
    _attachSecondaryEvents: function () {
      this._detachSecondaryEvents(), this._applyEvents((this || _global)._secondaryEvents);
    },
    _detachSecondaryEvents: function () {
      this._unapplyEvents((this || _global)._secondaryEvents);
    },
    _trigger: function (b, c) {
      var d = c || (this || _global).dates.get(-1),
          e = this._utc_to_local(d);

      (this || _global).element.trigger({
        type: b,
        date: e,
        viewMode: (this || _global).viewMode,
        dates: a.map((this || _global).dates, (this || _global)._utc_to_local),
        format: a.proxy(function (a, b) {
          0 === arguments.length ? (a = (this || _global).dates.length - 1, b = (this || _global).o.format) : "string" == typeof a && (b = a, a = (this || _global).dates.length - 1), b = b || (this || _global).o.format;

          var c = (this || _global).dates.get(a);

          return r.formatDate(c, b, (this || _global).o.language);
        }, this || _global)
      });
    },
    show: function () {
      if (!((this || _global).inputField.is(":disabled") || (this || _global).inputField.prop("readonly") && !1 === (this || _global).o.enableOnReadonly)) return (this || _global).isInline || (this || _global).picker.appendTo((this || _global).o.container), this.place(), (this || _global).picker.show(), this._attachSecondaryEvents(), this._trigger("show"), (window.navigator.msMaxTouchPoints || "ontouchstart" in document) && (this || _global).o.disableTouchKeyboard && a((this || _global).element).blur(), this || _global;
    },
    hide: function () {
      return (this || _global).isInline || !(this || _global).picker.is(":visible") ? this || _global : ((this || _global).focusDate = null, (this || _global).picker.hide().detach(), this._detachSecondaryEvents(), this.setViewMode((this || _global).o.startView), (this || _global).o.forceParse && (this || _global).inputField.val() && this.setValue(), this._trigger("hide"), this || _global);
    },
    destroy: function () {
      return this.hide(), this._detachEvents(), this._detachSecondaryEvents(), (this || _global).picker.remove(), delete (this || _global).element.data().datepicker, (this || _global).isInput || delete (this || _global).element.data().date, this || _global;
    },
    paste: function (b) {
      var c;
      if (b.originalEvent.clipboardData && b.originalEvent.clipboardData.types && -1 !== a.inArray("text/plain", b.originalEvent.clipboardData.types)) c = b.originalEvent.clipboardData.getData("text/plain");else {
        if (!window.clipboardData) return;
        c = window.clipboardData.getData("Text");
      }
      this.setDate(c), this.update(), b.preventDefault();
    },
    _utc_to_local: function (a) {
      if (!a) return a;
      var b = new Date(a.getTime() + 60000 * a.getTimezoneOffset());
      return b.getTimezoneOffset() !== a.getTimezoneOffset() && (b = new Date(a.getTime() + 60000 * b.getTimezoneOffset())), b;
    },
    _local_to_utc: function (a) {
      return a && new Date(a.getTime() - 60000 * a.getTimezoneOffset());
    },
    _zero_time: function (a) {
      return a && new Date(a.getFullYear(), a.getMonth(), a.getDate());
    },
    _zero_utc_time: function (a) {
      return a && c(a.getUTCFullYear(), a.getUTCMonth(), a.getUTCDate());
    },
    getDates: function () {
      return a.map((this || _global).dates, (this || _global)._utc_to_local);
    },
    getUTCDates: function () {
      return a.map((this || _global).dates, function (a) {
        return new Date(a);
      });
    },
    getDate: function () {
      return this._utc_to_local(this.getUTCDate());
    },
    getUTCDate: function () {
      var a = (this || _global).dates.get(-1);

      return a !== b ? new Date(a) : null;
    },
    clearDates: function () {
      (this || _global).inputField.val(""), this.update(), this._trigger("changeDate"), (this || _global).o.autoclose && this.hide();
    },
    setDates: function () {
      var b = a.isArray(arguments[0]) ? arguments[0] : arguments;
      return (this || _global).update.apply(this || _global, b), this._trigger("changeDate"), this.setValue(), this || _global;
    },
    setUTCDates: function () {
      var b = a.isArray(arguments[0]) ? arguments[0] : arguments;
      return (this || _global).setDates.apply(this || _global, a.map(b, (this || _global)._utc_to_local)), this || _global;
    },
    setDate: f("setDates"),
    setUTCDate: f("setUTCDates"),
    remove: f("destroy", "Method `remove` is deprecated and will be removed in version 2.0. Use `destroy` instead"),
    setValue: function () {
      var a = this.getFormattedDate();
      return (this || _global).inputField.val(a), this || _global;
    },
    getFormattedDate: function (c) {
      c === b && (c = (this || _global).o.format);
      var d = (this || _global).o.language;
      return a.map((this || _global).dates, function (a) {
        return r.formatDate(a, c, d);
      }).join((this || _global).o.multidateSeparator);
    },
    getStartDate: function () {
      return (this || _global).o.startDate;
    },
    setStartDate: function (a) {
      return this._process_options({
        startDate: a
      }), this.update(), this.updateNavArrows(), this || _global;
    },
    getEndDate: function () {
      return (this || _global).o.endDate;
    },
    setEndDate: function (a) {
      return this._process_options({
        endDate: a
      }), this.update(), this.updateNavArrows(), this || _global;
    },
    setDaysOfWeekDisabled: function (a) {
      return this._process_options({
        daysOfWeekDisabled: a
      }), this.update(), this || _global;
    },
    setDaysOfWeekHighlighted: function (a) {
      return this._process_options({
        daysOfWeekHighlighted: a
      }), this.update(), this || _global;
    },
    setDatesDisabled: function (a) {
      return this._process_options({
        datesDisabled: a
      }), this.update(), this || _global;
    },
    place: function () {
      if ((this || _global).isInline) return this || _global;

      var b = (this || _global).picker.outerWidth(),
          c = (this || _global).picker.outerHeight(),
          d = a((this || _global).o.container),
          e = d.width(),
          f = "body" === (this || _global).o.container ? a(document).scrollTop() : d.scrollTop(),
          g = d.offset(),
          h = [0];

      (this || _global).element.parents().each(function () {
        var b = a(this || _global).css("z-index");
        "auto" !== b && 0 !== Number(b) && h.push(Number(b));
      });

      var i = Math.max.apply(Math, h) + (this || _global).o.zIndexOffset,
          j = (this || _global).component ? (this || _global).component.parent().offset() : (this || _global).element.offset(),
          k = (this || _global).component ? (this || _global).component.outerHeight(!0) : (this || _global).element.outerHeight(!1),
          l = (this || _global).component ? (this || _global).component.outerWidth(!0) : (this || _global).element.outerWidth(!1),
          m = j.left - g.left,
          n = j.top - g.top;

      "body" !== (this || _global).o.container && (n += f), (this || _global).picker.removeClass("datepicker-orient-top datepicker-orient-bottom datepicker-orient-right datepicker-orient-left"), "auto" !== (this || _global).o.orientation.x ? ((this || _global).picker.addClass("datepicker-orient-" + (this || _global).o.orientation.x), "right" === (this || _global).o.orientation.x && (m -= b - l)) : j.left < 0 ? ((this || _global).picker.addClass("datepicker-orient-left"), m -= j.left - 10) : m + b > e ? ((this || _global).picker.addClass("datepicker-orient-right"), m += l - b) : (this || _global).o.rtl ? (this || _global).picker.addClass("datepicker-orient-right") : (this || _global).picker.addClass("datepicker-orient-left");
      var o,
          p = (this || _global).o.orientation.y;

      if ("auto" === p && (o = -f + n - c, p = o < 0 ? "bottom" : "top"), (this || _global).picker.addClass("datepicker-orient-" + p), "top" === p ? n -= c + parseInt((this || _global).picker.css("padding-top")) : n += k, (this || _global).o.rtl) {
        var q = e - (m + l);

        (this || _global).picker.css({
          top: n,
          right: q,
          zIndex: i
        });
      } else (this || _global).picker.css({
        top: n,
        left: m,
        zIndex: i
      });

      return this || _global;
    },
    _allow_update: !0,
    update: function () {
      if (!(this || _global)._allow_update) return this || _global;

      var b = (this || _global).dates.copy(),
          c = [],
          d = !1;

      return arguments.length ? (a.each(arguments, a.proxy(function (a, b) {
        b instanceof Date && (b = this._local_to_utc(b)), c.push(b);
      }, this || _global)), d = !0) : (c = (this || _global).isInput ? (this || _global).element.val() : (this || _global).element.data("date") || (this || _global).inputField.val(), c = c && (this || _global).o.multidate ? c.split((this || _global).o.multidateSeparator) : [c], delete (this || _global).element.data().date), c = a.map(c, a.proxy(function (a) {
        return r.parseDate(a, (this || _global).o.format, (this || _global).o.language, (this || _global).o.assumeNearbyYear);
      }, this || _global)), c = a.grep(c, a.proxy(function (a) {
        return !this.dateWithinRange(a) || !a;
      }, this || _global), !0), (this || _global).dates.replace(c), (this || _global).o.updateViewDate && ((this || _global).dates.length ? (this || _global).viewDate = new Date((this || _global).dates.get(-1)) : (this || _global).viewDate < (this || _global).o.startDate ? (this || _global).viewDate = new Date((this || _global).o.startDate) : (this || _global).viewDate > (this || _global).o.endDate ? (this || _global).viewDate = new Date((this || _global).o.endDate) : (this || _global).viewDate = (this || _global).o.defaultViewDate), d ? (this.setValue(), (this || _global).element.change()) : (this || _global).dates.length && String(b) !== String((this || _global).dates) && d && (this._trigger("changeDate"), (this || _global).element.change()), !(this || _global).dates.length && b.length && (this._trigger("clearDate"), (this || _global).element.change()), this.fill(), this || _global;
    },
    fillDow: function () {
      if ((this || _global).o.showWeekDays) {
        var b = (this || _global).o.weekStart,
            c = "<tr>";

        for ((this || _global).o.calendarWeeks && (c += "<th class=\"cw\">&#160;</th>"); b < (this || _global).o.weekStart + 7;) c += "<th class=\"dow", -1 !== a.inArray(b, (this || _global).o.daysOfWeekDisabled) && (c += " disabled"), c += "\">" + q[(this || _global).o.language].daysMin[b++ % 7] + "</th>";

        c += "</tr>", (this || _global).picker.find(".datepicker-days thead").append(c);
      }
    },
    fillMonths: function () {
      for (var a, b = this._utc_to_local((this || _global).viewDate), c = "", d = 0; d < 12; d++) a = b && b.getMonth() === d ? " focused" : "", c += "<span class=\"month" + a + "\">" + q[(this || _global).o.language].monthsShort[d] + "</span>";

      (this || _global).picker.find(".datepicker-months td").html(c);
    },
    setRange: function (b) {
      b && b.length ? (this || _global).range = a.map(b, function (a) {
        return a.valueOf();
      }) : delete (this || _global).range, this.fill();
    },
    getClassNames: function (b) {
      var c = [],
          f = (this || _global).viewDate.getUTCFullYear(),
          g = (this || _global).viewDate.getUTCMonth(),
          h = d();

      return b.getUTCFullYear() < f || b.getUTCFullYear() === f && b.getUTCMonth() < g ? c.push("old") : (b.getUTCFullYear() > f || b.getUTCFullYear() === f && b.getUTCMonth() > g) && c.push("new"), (this || _global).focusDate && b.valueOf() === (this || _global).focusDate.valueOf() && c.push("focused"), (this || _global).o.todayHighlight && e(b, h) && c.push("today"), -1 !== (this || _global).dates.contains(b) && c.push("active"), this.dateWithinRange(b) || c.push("disabled"), this.dateIsDisabled(b) && c.push("disabled", "disabled-date"), -1 !== a.inArray(b.getUTCDay(), (this || _global).o.daysOfWeekHighlighted) && c.push("highlighted"), (this || _global).range && (b > (this || _global).range[0] && b < (this || _global).range[(this || _global).range.length - 1] && c.push("range"), -1 !== a.inArray(b.valueOf(), (this || _global).range) && c.push("selected"), b.valueOf() === (this || _global).range[0] && c.push("range-start"), b.valueOf() === (this || _global).range[(this || _global).range.length - 1] && c.push("range-end")), c;
    },
    _fill_yearsView: function (c, d, e, f, g, h, i) {
      for (var j, k, l, m = "", n = e / 10, o = (this || _global).picker.find(c), p = Math.floor(f / e) * e, q = p + 9 * n, r = Math.floor((this || _global).viewDate.getFullYear() / n) * n, s = a.map((this || _global).dates, function (a) {
        return Math.floor(a.getUTCFullYear() / n) * n;
      }), t = p - n; t <= q + n; t += n) j = [d], k = null, t === p - n ? j.push("old") : t === q + n && j.push("new"), -1 !== a.inArray(t, s) && j.push("active"), (t < g || t > h) && j.push("disabled"), t === r && j.push("focused"), i !== a.noop && (l = i(new Date(t, 0, 1)), l === b ? l = {} : "boolean" == typeof l ? l = {
        enabled: l
      } : "string" == typeof l && (l = {
        classes: l
      }), !1 === l.enabled && j.push("disabled"), l.classes && (j = j.concat(l.classes.split(/\s+/))), l.tooltip && (k = l.tooltip)), m += "<span class=\"" + j.join(" ") + "\"" + (k ? " title=\"" + k + "\"" : "") + ">" + t + "</span>";

      o.find(".datepicker-switch").text(p + "-" + q), o.find("td").html(m);
    },
    fill: function () {
      var e,
          f,
          g = new Date((this || _global).viewDate),
          h = g.getUTCFullYear(),
          i = g.getUTCMonth(),
          j = (this || _global).o.startDate !== -1 / 0 ? (this || _global).o.startDate.getUTCFullYear() : -1 / 0,
          k = (this || _global).o.startDate !== -1 / 0 ? (this || _global).o.startDate.getUTCMonth() : -1 / 0,
          l = (this || _global).o.endDate !== 1 / 0 ? (this || _global).o.endDate.getUTCFullYear() : 1 / 0,
          m = (this || _global).o.endDate !== 1 / 0 ? (this || _global).o.endDate.getUTCMonth() : 1 / 0,
          n = q[(this || _global).o.language].today || q.en.today || "",
          o = q[(this || _global).o.language].clear || q.en.clear || "",
          p = q[(this || _global).o.language].titleFormat || q.en.titleFormat,
          s = d(),
          t = (!0 === (this || _global).o.todayBtn || "linked" === (this || _global).o.todayBtn) && s >= (this || _global).o.startDate && s <= (this || _global).o.endDate && !this.weekOfDateIsDisabled(s);

      if (!isNaN(h) && !isNaN(i)) {
        (this || _global).picker.find(".datepicker-days .datepicker-switch").text(r.formatDate(g, p, (this || _global).o.language)), (this || _global).picker.find("tfoot .today").text(n).css("display", t ? "table-cell" : "none"), (this || _global).picker.find("tfoot .clear").text(o).css("display", !0 === (this || _global).o.clearBtn ? "table-cell" : "none"), (this || _global).picker.find("thead .datepicker-title").text((this || _global).o.title).css("display", "string" == typeof (this || _global).o.title && "" !== (this || _global).o.title ? "table-cell" : "none"), this.updateNavArrows(), this.fillMonths();
        var u = c(h, i, 0),
            v = u.getUTCDate();
        u.setUTCDate(v - (u.getUTCDay() - (this || _global).o.weekStart + 7) % 7);
        var w = new Date(u);
        u.getUTCFullYear() < 100 && w.setUTCFullYear(u.getUTCFullYear()), w.setUTCDate(w.getUTCDate() + 42), w = w.valueOf();

        for (var x, y, z = []; u.valueOf() < w;) {
          if ((x = u.getUTCDay()) === (this || _global).o.weekStart && (z.push("<tr>"), (this || _global).o.calendarWeeks)) {
            var A = new Date(+u + ((this || _global).o.weekStart - x - 7) % 7 * 86400000),
                B = new Date(Number(A) + (11 - A.getUTCDay()) % 7 * 86400000),
                C = new Date(Number(C = c(B.getUTCFullYear(), 0, 1)) + (11 - C.getUTCDay()) % 7 * 86400000),
                D = (B - C) / 86400000 / 7 + 1;
            z.push("<td class=\"cw\">" + D + "</td>");
          }

          y = this.getClassNames(u), y.push("day");
          var E = u.getUTCDate();
          (this || _global).o.beforeShowDay !== a.noop && (f = (this || _global).o.beforeShowDay(this._utc_to_local(u)), f === b ? f = {} : "boolean" == typeof f ? f = {
            enabled: f
          } : "string" == typeof f && (f = {
            classes: f
          }), !1 === f.enabled && y.push("disabled"), f.classes && (y = y.concat(f.classes.split(/\s+/))), f.tooltip && (e = f.tooltip), f.content && (E = f.content)), y = a.isFunction(a.uniqueSort) ? a.uniqueSort(y) : a.unique(y), z.push("<td class=\"" + y.join(" ") + "\"" + (e ? " title=\"" + e + "\"" : "") + " data-date=\"" + u.getTime().toString() + "\">" + E + "</td>"), e = null, x === (this || _global).o.weekEnd && z.push("</tr>"), u.setUTCDate(u.getUTCDate() + 1);
        }

        (this || _global).picker.find(".datepicker-days tbody").html(z.join(""));

        var F = q[(this || _global).o.language].monthsTitle || q.en.monthsTitle || "Months",
            G = (this || _global).picker.find(".datepicker-months").find(".datepicker-switch").text((this || _global).o.maxViewMode < 2 ? F : h).end().find("tbody span").removeClass("active");

        if (a.each((this || _global).dates, function (a, b) {
          b.getUTCFullYear() === h && G.eq(b.getUTCMonth()).addClass("active");
        }), (h < j || h > l) && G.addClass("disabled"), h === j && G.slice(0, k).addClass("disabled"), h === l && G.slice(m + 1).addClass("disabled"), (this || _global).o.beforeShowMonth !== a.noop) {
          var H = this || _global;
          a.each(G, function (c, d) {
            var e = new Date(h, c, 1),
                f = H.o.beforeShowMonth(e);
            f === b ? f = {} : "boolean" == typeof f ? f = {
              enabled: f
            } : "string" == typeof f && (f = {
              classes: f
            }), !1 !== f.enabled || a(d).hasClass("disabled") || a(d).addClass("disabled"), f.classes && a(d).addClass(f.classes), f.tooltip && a(d).prop("title", f.tooltip);
          });
        }

        this._fill_yearsView(".datepicker-years", "year", 10, h, j, l, (this || _global).o.beforeShowYear), this._fill_yearsView(".datepicker-decades", "decade", 100, h, j, l, (this || _global).o.beforeShowDecade), this._fill_yearsView(".datepicker-centuries", "century", 1000, h, j, l, (this || _global).o.beforeShowCentury);
      }
    },
    updateNavArrows: function () {
      if ((this || _global)._allow_update) {
        var a,
            b,
            c = new Date((this || _global).viewDate),
            d = c.getUTCFullYear(),
            e = c.getUTCMonth(),
            f = (this || _global).o.startDate !== -1 / 0 ? (this || _global).o.startDate.getUTCFullYear() : -1 / 0,
            g = (this || _global).o.startDate !== -1 / 0 ? (this || _global).o.startDate.getUTCMonth() : -1 / 0,
            h = (this || _global).o.endDate !== 1 / 0 ? (this || _global).o.endDate.getUTCFullYear() : 1 / 0,
            i = (this || _global).o.endDate !== 1 / 0 ? (this || _global).o.endDate.getUTCMonth() : 1 / 0,
            j = 1;

        switch ((this || _global).viewMode) {
          case 4:
            j *= 10;

          case 3:
            j *= 10;

          case 2:
            j *= 10;

          case 1:
            a = Math.floor(d / j) * j <= f, b = Math.floor(d / j) * j + j > h;
            break;

          case 0:
            a = d <= f && e <= g, b = d >= h && e >= i;
        }

        (this || _global).picker.find(".prev").toggleClass("disabled", a), (this || _global).picker.find(".next").toggleClass("disabled", b);
      }
    },
    click: function (b) {
      b.preventDefault(), b.stopPropagation();
      var e, f, g, h;
      e = a(b.target), e.hasClass("datepicker-switch") && (this || _global).viewMode !== (this || _global).o.maxViewMode && this.setViewMode((this || _global).viewMode + 1), e.hasClass("today") && !e.hasClass("day") && (this.setViewMode(0), this._setDate(d(), "linked" === (this || _global).o.todayBtn ? null : "view")), e.hasClass("clear") && this.clearDates(), e.hasClass("disabled") || (e.hasClass("month") || e.hasClass("year") || e.hasClass("decade") || e.hasClass("century")) && ((this || _global).viewDate.setUTCDate(1), f = 1, 1 === (this || _global).viewMode ? (h = e.parent().find("span").index(e), g = (this || _global).viewDate.getUTCFullYear(), (this || _global).viewDate.setUTCMonth(h)) : (h = 0, g = Number(e.text()), (this || _global).viewDate.setUTCFullYear(g)), this._trigger(r.viewModes[(this || _global).viewMode - 1].e, (this || _global).viewDate), (this || _global).viewMode === (this || _global).o.minViewMode ? this._setDate(c(g, h, f)) : (this.setViewMode((this || _global).viewMode - 1), this.fill())), (this || _global).picker.is(":visible") && (this || _global)._focused_from && (this || _global)._focused_from.focus(), delete (this || _global)._focused_from;
    },
    dayCellClick: function (b) {
      var c = a(b.currentTarget),
          d = c.data("date"),
          e = new Date(d);
      (this || _global).o.updateViewDate && (e.getUTCFullYear() !== (this || _global).viewDate.getUTCFullYear() && this._trigger("changeYear", (this || _global).viewDate), e.getUTCMonth() !== (this || _global).viewDate.getUTCMonth() && this._trigger("changeMonth", (this || _global).viewDate)), this._setDate(e);
    },
    navArrowsClick: function (b) {
      var c = a(b.currentTarget),
          d = c.hasClass("prev") ? -1 : 1;
      0 !== (this || _global).viewMode && (d *= 12 * r.viewModes[(this || _global).viewMode].navStep), (this || _global).viewDate = this.moveMonth((this || _global).viewDate, d), this._trigger(r.viewModes[(this || _global).viewMode].e, (this || _global).viewDate), this.fill();
    },
    _toggle_multidate: function (a) {
      var b = (this || _global).dates.contains(a);

      if (a || (this || _global).dates.clear(), -1 !== b ? (!0 === (this || _global).o.multidate || (this || _global).o.multidate > 1 || (this || _global).o.toggleActive) && (this || _global).dates.remove(b) : !1 === (this || _global).o.multidate ? ((this || _global).dates.clear(), (this || _global).dates.push(a)) : (this || _global).dates.push(a), "number" == typeof (this || _global).o.multidate) for (; (this || _global).dates.length > (this || _global).o.multidate;) (this || _global).dates.remove(0);
    },
    _setDate: function (a, b) {
      b && "date" !== b || this._toggle_multidate(a && new Date(a)), (!b && (this || _global).o.updateViewDate || "view" === b) && ((this || _global).viewDate = a && new Date(a)), this.fill(), this.setValue(), b && "view" === b || this._trigger("changeDate"), (this || _global).inputField.trigger("change"), !(this || _global).o.autoclose || b && "date" !== b || this.hide();
    },
    moveDay: function (a, b) {
      var c = new Date(a);
      return c.setUTCDate(a.getUTCDate() + b), c;
    },
    moveWeek: function (a, b) {
      return this.moveDay(a, 7 * b);
    },
    moveMonth: function (a, b) {
      if (!g(a)) return (this || _global).o.defaultViewDate;
      if (!b) return a;
      var c,
          d,
          e = new Date(a.valueOf()),
          f = e.getUTCDate(),
          h = e.getUTCMonth(),
          i = Math.abs(b);
      if (b = b > 0 ? 1 : -1, 1 === i) d = -1 === b ? function () {
        return e.getUTCMonth() === h;
      } : function () {
        return e.getUTCMonth() !== c;
      }, c = h + b, e.setUTCMonth(c), c = (c + 12) % 12;else {
        for (var j = 0; j < i; j++) e = this.moveMonth(e, b);

        c = e.getUTCMonth(), e.setUTCDate(f), d = function () {
          return c !== e.getUTCMonth();
        };
      }

      for (; d();) e.setUTCDate(--f), e.setUTCMonth(c);

      return e;
    },
    moveYear: function (a, b) {
      return this.moveMonth(a, 12 * b);
    },
    moveAvailableDate: function (a, b, c) {
      do {
        if (a = this[c](a, b), !this.dateWithinRange(a)) return !1;
        c = "moveDay";
      } while (this.dateIsDisabled(a));

      return a;
    },
    weekOfDateIsDisabled: function (b) {
      return -1 !== a.inArray(b.getUTCDay(), (this || _global).o.daysOfWeekDisabled);
    },
    dateIsDisabled: function (b) {
      return this.weekOfDateIsDisabled(b) || a.grep((this || _global).o.datesDisabled, function (a) {
        return e(b, a);
      }).length > 0;
    },
    dateWithinRange: function (a) {
      return a >= (this || _global).o.startDate && a <= (this || _global).o.endDate;
    },
    keydown: function (a) {
      if (!(this || _global).picker.is(":visible")) return void (40 !== a.keyCode && 27 !== a.keyCode || (this.show(), a.stopPropagation()));
      var b,
          c,
          d = !1,
          e = (this || _global).focusDate || (this || _global).viewDate;

      switch (a.keyCode) {
        case 27:
          (this || _global).focusDate ? ((this || _global).focusDate = null, (this || _global).viewDate = (this || _global).dates.get(-1) || (this || _global).viewDate, this.fill()) : this.hide(), a.preventDefault(), a.stopPropagation();
          break;

        case 37:
        case 38:
        case 39:
        case 40:
          if (!(this || _global).o.keyboardNavigation || 7 === (this || _global).o.daysOfWeekDisabled.length) break;
          b = 37 === a.keyCode || 38 === a.keyCode ? -1 : 1, 0 === (this || _global).viewMode ? a.ctrlKey ? (c = this.moveAvailableDate(e, b, "moveYear")) && this._trigger("changeYear", (this || _global).viewDate) : a.shiftKey ? (c = this.moveAvailableDate(e, b, "moveMonth")) && this._trigger("changeMonth", (this || _global).viewDate) : 37 === a.keyCode || 39 === a.keyCode ? c = this.moveAvailableDate(e, b, "moveDay") : this.weekOfDateIsDisabled(e) || (c = this.moveAvailableDate(e, b, "moveWeek")) : 1 === (this || _global).viewMode ? (38 !== a.keyCode && 40 !== a.keyCode || (b *= 4), c = this.moveAvailableDate(e, b, "moveMonth")) : 2 === (this || _global).viewMode && (38 !== a.keyCode && 40 !== a.keyCode || (b *= 4), c = this.moveAvailableDate(e, b, "moveYear")), c && ((this || _global).focusDate = (this || _global).viewDate = c, this.setValue(), this.fill(), a.preventDefault());
          break;

        case 13:
          if (!(this || _global).o.forceParse) break;
          e = (this || _global).focusDate || (this || _global).dates.get(-1) || (this || _global).viewDate, (this || _global).o.keyboardNavigation && (this._toggle_multidate(e), d = !0), (this || _global).focusDate = null, (this || _global).viewDate = (this || _global).dates.get(-1) || (this || _global).viewDate, this.setValue(), this.fill(), (this || _global).picker.is(":visible") && (a.preventDefault(), a.stopPropagation(), (this || _global).o.autoclose && this.hide());
          break;

        case 9:
          (this || _global).focusDate = null, (this || _global).viewDate = (this || _global).dates.get(-1) || (this || _global).viewDate, this.fill(), this.hide();
      }

      d && ((this || _global).dates.length ? this._trigger("changeDate") : this._trigger("clearDate"), (this || _global).inputField.trigger("change"));
    },
    setViewMode: function (a) {
      (this || _global).viewMode = a, (this || _global).picker.children("div").hide().filter(".datepicker-" + r.viewModes[(this || _global).viewMode].clsName).show(), this.updateNavArrows(), this._trigger("changeViewMode", new Date((this || _global).viewDate));
    }
  };

  var l = function (b, c) {
    a.data(b, "datepicker", this || _global), (this || _global).element = a(b), (this || _global).inputs = a.map(c.inputs, function (a) {
      return a.jquery ? a[0] : a;
    }), delete c.inputs, (this || _global).keepEmptyValues = c.keepEmptyValues, delete c.keepEmptyValues, n.call(a((this || _global).inputs), c).on("changeDate", a.proxy((this || _global).dateUpdated, this || _global)), (this || _global).pickers = a.map((this || _global).inputs, function (b) {
      return a.data(b, "datepicker");
    }), this.updateDates();
  };

  l.prototype = {
    updateDates: function () {
      (this || _global).dates = a.map((this || _global).pickers, function (a) {
        return a.getUTCDate();
      }), this.updateRanges();
    },
    updateRanges: function () {
      var b = a.map((this || _global).dates, function (a) {
        return a.valueOf();
      });
      a.each((this || _global).pickers, function (a, c) {
        c.setRange(b);
      });
    },
    clearDates: function () {
      a.each((this || _global).pickers, function (a, b) {
        b.clearDates();
      });
    },
    dateUpdated: function (c) {
      if (!(this || _global).updating) {
        (this || _global).updating = !0;
        var d = a.data(c.target, "datepicker");

        if (d !== b) {
          var e = d.getUTCDate(),
              f = (this || _global).keepEmptyValues,
              g = a.inArray(c.target, (this || _global).inputs),
              h = g - 1,
              i = g + 1,
              j = (this || _global).inputs.length;

          if (-1 !== g) {
            if (a.each((this || _global).pickers, function (a, b) {
              b.getUTCDate() || b !== d && f || b.setUTCDate(e);
            }), e < (this || _global).dates[h]) for (; h >= 0 && e < (this || _global).dates[h];) (this || _global).pickers[h--].setUTCDate(e);else if (e > (this || _global).dates[i]) for (; i < j && e > (this || _global).dates[i];) (this || _global).pickers[i++].setUTCDate(e);
            this.updateDates(), delete (this || _global).updating;
          }
        }
      }
    },
    destroy: function () {
      a.map((this || _global).pickers, function (a) {
        a.destroy();
      }), a((this || _global).inputs).off("changeDate", (this || _global).dateUpdated), delete (this || _global).element.data().datepicker;
    },
    remove: f("destroy", "Method `remove` is deprecated and will be removed in version 2.0. Use `destroy` instead")
  };

  var m = a.fn.datepicker,
      n = function (c) {
    var d = Array.apply(null, arguments);
    d.shift();
    var e;
    if (this.each(function () {
      var b = a(this || _global),
          f = b.data("datepicker"),
          g = "object" == typeof c && c;

      if (!f) {
        var j = h(this || _global, "date"),
            m = a.extend({}, o, j, g),
            n = i(m.language),
            p = a.extend({}, o, n, j, g);
        b.hasClass("input-daterange") || p.inputs ? (a.extend(p, {
          inputs: p.inputs || b.find("input").toArray()
        }), f = new l(this || _global, p)) : f = new k(this || _global, p), b.data("datepicker", f);
      }

      "string" == typeof c && "function" == typeof f[c] && (e = f[c].apply(f, d));
    }), e === b || e instanceof k || e instanceof l) return this || _global;
    if ((this || _global).length > 1) throw new Error("Using only allowed for the collection of a single element (" + c + " function)");
    return e;
  };

  a.fn.datepicker = n;
  var o = a.fn.datepicker.defaults = {
    assumeNearbyYear: !1,
    autoclose: !1,
    beforeShowDay: a.noop,
    beforeShowMonth: a.noop,
    beforeShowYear: a.noop,
    beforeShowDecade: a.noop,
    beforeShowCentury: a.noop,
    calendarWeeks: !1,
    clearBtn: !1,
    toggleActive: !1,
    daysOfWeekDisabled: [],
    daysOfWeekHighlighted: [],
    datesDisabled: [],
    endDate: 1 / 0,
    forceParse: !0,
    format: "mm/dd/yyyy",
    keepEmptyValues: !1,
    keyboardNavigation: !0,
    language: "en",
    minViewMode: 0,
    maxViewMode: 4,
    multidate: !1,
    multidateSeparator: ",",
    orientation: "auto",
    rtl: !1,
    startDate: -1 / 0,
    startView: 0,
    todayBtn: !1,
    todayHighlight: !1,
    updateViewDate: !0,
    weekStart: 0,
    disableTouchKeyboard: !1,
    enableOnReadonly: !0,
    showOnFocus: !0,
    zIndexOffset: 10,
    container: "body",
    immediateUpdates: !1,
    title: "",
    templates: {
      leftArrow: "&#x00AB;",
      rightArrow: "&#x00BB;"
    },
    showWeekDays: !0
  },
      p = a.fn.datepicker.locale_opts = ["format", "rtl", "weekStart"];
  a.fn.datepicker.Constructor = k;
  var q = a.fn.datepicker.dates = {
    en: {
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: "Today",
      clear: "Clear",
      titleFormat: "MM yyyy"
    }
  },
      r = {
    viewModes: [{
      names: ["days", "month"],
      clsName: "days",
      e: "changeMonth"
    }, {
      names: ["months", "year"],
      clsName: "months",
      e: "changeYear",
      navStep: 1
    }, {
      names: ["years", "decade"],
      clsName: "years",
      e: "changeDecade",
      navStep: 10
    }, {
      names: ["decades", "century"],
      clsName: "decades",
      e: "changeCentury",
      navStep: 100
    }, {
      names: ["centuries", "millennium"],
      clsName: "centuries",
      e: "changeMillennium",
      navStep: 1000
    }],
    validParts: /dd?|DD?|mm?|MM?|yy(?:yy)?/g,
    nonpunctuation: /[^ -\/:-@\u5e74\u6708\u65e5\[-`{-~\t\n\r]+/g,
    parseFormat: function (a) {
      if ("function" == typeof a.toValue && "function" == typeof a.toDisplay) return a;
      var b = a.replace((this || _global).validParts, "\0").split("\0"),
          c = a.match((this || _global).validParts);
      if (!b || !b.length || !c || 0 === c.length) throw new Error("Invalid date format.");
      return {
        separators: b,
        parts: c
      };
    },
    parseDate: function (c, e, f, g) {
      function h(a, b) {
        return !0 === b && (b = 10), a < 100 && (a += 2000) > new Date().getFullYear() + b && (a -= 100), a;
      }

      function i() {
        var a = this.slice(0, j[n].length),
            b = j[n].slice(0, a.length);
        return a.toLowerCase() === b.toLowerCase();
      }

      if (!c) return b;
      if (c instanceof Date) return c;
      if ("string" == typeof e && (e = r.parseFormat(e)), e.toValue) return e.toValue(c, e, f);
      var j,
          l,
          m,
          n,
          o,
          p = {
        d: "moveDay",
        m: "moveMonth",
        w: "moveWeek",
        y: "moveYear"
      },
          s = {
        yesterday: "-1d",
        today: "+0d",
        tomorrow: "+1d"
      };

      if (c in s && (c = s[c]), /^[\-+]\d+[dmwy]([\s,]+[\-+]\d+[dmwy])*$/i.test(c)) {
        for (j = c.match(/([\-+]\d+)([dmwy])/gi), c = new Date(), n = 0; n < j.length; n++) l = j[n].match(/([\-+]\d+)([dmwy])/i), m = Number(l[1]), o = p[l[2].toLowerCase()], c = k.prototype[o](c, m);

        return k.prototype._zero_utc_time(c);
      }

      j = c && c.match((this || _global).nonpunctuation) || [];
      var t,
          u,
          v = {},
          w = ["yyyy", "yy", "M", "MM", "m", "mm", "d", "dd"],
          x = {
        yyyy: function (a, b) {
          return a.setUTCFullYear(g ? h(b, g) : b);
        },
        m: function (a, b) {
          if (isNaN(a)) return a;

          for (b -= 1; b < 0;) b += 12;

          for (b %= 12, a.setUTCMonth(b); a.getUTCMonth() !== b;) a.setUTCDate(a.getUTCDate() - 1);

          return a;
        },
        d: function (a, b) {
          return a.setUTCDate(b);
        }
      };
      x.yy = x.yyyy, x.M = x.MM = x.mm = x.m, x.dd = x.d, c = d();
      var y = e.parts.slice();

      if (j.length !== y.length && (y = a(y).filter(function (b, c) {
        return -1 !== a.inArray(c, w);
      }).toArray()), j.length === y.length) {
        var z;

        for (n = 0, z = y.length; n < z; n++) {
          if (t = parseInt(j[n], 10), l = y[n], isNaN(t)) switch (l) {
            case "MM":
              u = a(q[f].months).filter(i), t = a.inArray(u[0], q[f].months) + 1;
              break;

            case "M":
              u = a(q[f].monthsShort).filter(i), t = a.inArray(u[0], q[f].monthsShort) + 1;
          }
          v[l] = t;
        }

        var A, B;

        for (n = 0; n < w.length; n++) (B = w[n]) in v && !isNaN(v[B]) && (A = new Date(c), x[B](A, v[B]), isNaN(A) || (c = A));
      }

      return c;
    },
    formatDate: function (b, c, d) {
      if (!b) return "";
      if ("string" == typeof c && (c = r.parseFormat(c)), c.toDisplay) return c.toDisplay(b, c, d);
      var e = {
        d: b.getUTCDate(),
        D: q[d].daysShort[b.getUTCDay()],
        DD: q[d].days[b.getUTCDay()],
        m: b.getUTCMonth() + 1,
        M: q[d].monthsShort[b.getUTCMonth()],
        MM: q[d].months[b.getUTCMonth()],
        yy: b.getUTCFullYear().toString().substring(2),
        yyyy: b.getUTCFullYear()
      };
      e.dd = (e.d < 10 ? "0" : "") + e.d, e.mm = (e.m < 10 ? "0" : "") + e.m, b = [];

      for (var f = a.extend([], c.separators), g = 0, h = c.parts.length; g <= h; g++) f.length && b.push(f.shift()), b.push(e[c.parts[g]]);

      return b.join("");
    },
    headTemplate: "<thead><tr><th colspan=\"7\" class=\"datepicker-title\"></th></tr><tr><th class=\"prev\">" + o.templates.leftArrow + "</th><th colspan=\"5\" class=\"datepicker-switch\"></th><th class=\"next\">" + o.templates.rightArrow + "</th></tr></thead>",
    contTemplate: "<tbody><tr><td colspan=\"7\"></td></tr></tbody>",
    footTemplate: "<tfoot><tr><th colspan=\"7\" class=\"today\"></th></tr><tr><th colspan=\"7\" class=\"clear\"></th></tr></tfoot>"
  };
  r.template = "<div class=\"datepicker\"><div class=\"datepicker-days\"><table class=\"table-condensed\">" + r.headTemplate + "<tbody></tbody>" + r.footTemplate + "</table></div><div class=\"datepicker-months\"><table class=\"table-condensed\">" + r.headTemplate + r.contTemplate + r.footTemplate + "</table></div><div class=\"datepicker-years\"><table class=\"table-condensed\">" + r.headTemplate + r.contTemplate + r.footTemplate + "</table></div><div class=\"datepicker-decades\"><table class=\"table-condensed\">" + r.headTemplate + r.contTemplate + r.footTemplate + "</table></div><div class=\"datepicker-centuries\"><table class=\"table-condensed\">" + r.headTemplate + r.contTemplate + r.footTemplate + "</table></div></div>", a.fn.datepicker.DPGlobal = r, a.fn.datepicker.noConflict = function () {
    return a.fn.datepicker = m, this || _global;
  }, a.fn.datepicker.version = "1.9.0", a.fn.datepicker.deprecated = function (a) {
    var b = window.console;
    b && b.warn && b.warn("DEPRECATED: " + a);
  }, a(document).on("focus.datepicker.data-api click.datepicker.data-api", "[data-provide=\"datepicker\"]", function (b) {
    var c = a(this || _global);
    c.data("datepicker") || (b.preventDefault(), n.call(c, "show"));
  }), a(function () {
    n.call(a("[data-provide=\"datepicker-inline\"]"));
  });
});
export default {};